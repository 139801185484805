import React, { Component } from 'react'
import { node, string } from 'prop-types'
import { isURL } from 'validator'
import URL from 'url-parse'

const NOTIFY_TIMEOUT = 2500

class StatsForm extends Component {
  constructor () {
    super()
    this.state = {
      destination: '',
      isLoading: false,
      notify: {}
    }
  }

  validate (e) {
    e.preventDefault()
    const { hostname } = new URL(this.state.destination.startsWith('http') ? this.state.destination : `http://${this.state.destination}`, {}, true)
    if (hostname !== 'rb.gy' || !isURL(this.state.destination)) {
      this.setState({ notify: { message: 'Invalid URL', type: 'error' } })
      this.timeout && clearTimeout(this.timeout)
      this.timeout = setTimeout(() => this.setState({ notify: {} }), NOTIFY_TIMEOUT)
      return false
    } else {
      window.open(new URL(this.state.destination.startsWith('http') ? `${this.state.destination}+` : `http://${this.state.destination}+`, '_blank'))
    }
  }

  renderNotification () {
    if (this.state.notify.type) {
      if (this.state.notify.type === 'error') {
        return (
          <div className='shortened-links-notify-container'>
            <div className='shortened-links-notify error'>
              <svg
                width={16}
                height={16}
                viewBox='0 0 16 16'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M1.65844 13.9181C1.8066 14.0037 1.97466 14.0487 2.14573 14.0487H13.8548C14.3931 14.0487 14.8294 13.6121 14.8294 13.0735C14.8294 12.9024 14.7844 12.7342 14.6989 12.586L8.84436 2.43898C8.57524 1.97253 7.97915 1.81269 7.51296 2.08196C7.36475 2.16756 7.24169 2.29069 7.15613 2.43898L1.30162 12.586C1.03249 13.0524 1.19225 13.6488 1.65844 13.9181ZM8.58553 11.317C8.58553 11.6403 8.32346 11.9024 8.00017 11.9024C7.67688 11.9024 7.4148 11.6403 7.4148 11.317C7.4148 10.9937 7.67688 10.7317 8.00017 10.7317C8.32346 10.7317 8.58553 10.9937 8.58553 11.317ZM8.00017 9.95117C8.21569 9.95117 8.39041 9.77645 8.39041 9.56093V6.43898C8.39041 6.22345 8.21569 6.04873 8.00017 6.04873C7.78464 6.04873 7.60992 6.22345 7.60992 6.43898V9.56093C7.60992 9.77645 7.78464 9.95117 8.00017 9.95117Z'
                  fill='#D34547'
                />
              </svg>
              <span>{this.state.notify.message}</span>
            </div>
          </div>
        )
      } else if (this.state.notify.type === 'info') {
        return (
          <div className='shortened-links-notify-container'>
            <div className='shortened-links-notify success'>
              <svg width='16' height='16' viewBox='0 0 16 16' fill='none'>
                <path fillRule='evenodd' clipRule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z' fill='#02AF63' />
                <path d='M6.61899 9.79217L4.82228 8.09006L4.21045 8.66559L6.61899 10.9473L11.7894 6.04917L11.1819 5.47363L6.61899 9.79217Z' fill='white' />
              </svg>
              <span>{this.state.notify.message}</span>
            </div>
          </div>
        )
      }
    }
  }

  render () {
    return (
      <div className={`form form-small-pad shorten-section ${this.props.className}`}>
        {this.props.title || null}
        <div className='container hero'>
          <div className='div_form_horizontal_form'>
            <div className='rebrand-link-block home'>
              <div className='w-form'>
                {this.props.description || null}
                <form
                  onSubmit={(e) => this.validate(e)}
                  id='wf-form-rebrand-link-form'
                  name='wf-form-rebrand-link-form'
                  data-name='rebrand-link-form'
                  className='form-link-creation home'
                >
                  {this.renderNotification()}
                  <label htmlFor='url' className='text-indent'>Destination</label>
                  <input
                    type='text'
                    className='form-link-creation-input w-input'
                    maxLength={256}
                    name='url'
                    data-name='Url'
                    placeholder={this.props.placeholder}
                    id='url'
                    value={this.state.isLoading ? '' : this.state.destination}
                    onChange={e => this.setState({ destination: e.target.value })}
                    autoComplete='off'
                    autoCorrect='off'
                    autoCapitalize='none'
                    disabled={this.state.isLoading}
                  />
                  <input
                    type='submit'
                    value='View Clicks'
                    disabled={this.state.isLoading}
                    className='btn-primary-3 w-button'
                  />
                </form>
                <div className='terms-copy'>
                  <a href='/'>Short another URL</a>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

StatsForm.propTypes = {
  title: node,
  placeholder: string,
  description: node,
  className: string
}

export default StatsForm
