import React from 'react'
import Link from './anchor'

const SubFooter = () => (
  <div className='sub-footer'>
    <div className='container sub-footer-elements'>
      <div className='links-sub-footer'>
        <Link href='https://rebrandly.com/terms-conditions' target='_blank' className='link-white'>
          Terms &amp; Conditions
        </Link>
        <Link href='https://rebrandly.com/privacy-policy' target='_blank' className='link-white'>
          Privacy Policy
        </Link>
        <Link href='https://rebrandly.com/cookies' target='_blank' className='link-white'>
          Cookie Policy
        </Link>
        <Link href='https://rebrandly.com/anti-spam' target='_blank' className='link-white'>
          Anti Spam Policy
        </Link>
        <Link href='mailto:support@rebrandly.com?subject=Abuse-RbGy' target='_blank' className='link-white'>
          Report abuse
        </Link>
      </div>
      <div>
        <Link href='https://rebrandly.com' className='link-white w--current'>
          Rebrandly © Copyright {new Date().getFullYear()}
        </Link>
      </div>
    </div>
  </div>
)

export default SubFooter
